import React from "react";
import { ToolFilled } from "@ant-design/icons";

const App = () => {
  return (
    <div className="App">
      <div className="construction-container">
        <div className="construction-icon">
          <ToolFilled style={{ fontSize: "80px", color: "#e74c3c" }} />
        </div>
        <div className="construction-text">This website is under construction. Please check back later.</div>
        <div className="construction-text">+91 8080004567 / nozeperfumes@gmail.com</div>
      </div>
    </div>
  );
};

export default App;
